import Api from "@/config/Api";

export default {
  checkoutPayLink(data) {
    return Api().post("/pay-gateway/df/pl-invoices/checkout", data);
  },
  confirmPayLink(data) {
    return Api().post("/pay-gateway/pp/pl-invoices/confirm", data);
  },
  confirmAdditional(data) {
    return Api().post("/additional/confirm", data);
  }
};

export const messageWaiting =
  "Por favor espere un momento, estamos validando la transacción.";
