<template>
  <div>
    <div
      class="checkout-error d-flex justify-content-center align-items-center flex-column"
    >
      <img :src="icoNotifyDanger" alt="error" class="checkout-img-error" />

      <h3 class="h3 font-bold mb-2 text-center mt-4">
        ¡Upss!
      </h3>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { icoNotifyDanger } from "@/services/resources";

export default {
  name: "CheckoutSuccess",
  data() {
    return {
      icoNotifyDanger
    };
  }
};
</script>

<style lang="scss" scoped>
.checkout-img-error {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
