<template>
  <div
    class="md__border border-radius bg-color-write m-4 box-shadow p-4"
    v-if="img"
  >
    <div class="d-flex justify-content-center">
      <img :src="img" :width="width" :alt="alt" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoCard",
  props: {
    img: {
      default: null
    },
    alt: {
      default: null
    },
    width: {
      default: "200px"
    }
  }
};
</script>

<style lang="scss" scoped></style>
