<template>
  <div>
    <div
      class="checkout-successfully d-flex justify-content-center align-items-center flex-column"
    >
      <img
        :src="icoCheckMarkSuccess"
        alt="successfully"
        class="checkout-img-successfully"
      />

      <h3 class="h3 font-bold mb-2 text-center mt-4">
        ¡Pago completado!
      </h3>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { icoCheckMarkSuccess } from "@/services/resources";

export default {
  name: "CheckoutSuccess",
  data() {
    return {
      icoCheckMarkSuccess
    };
  }
};
</script>

<style lang="scss" scoped>
.checkout-img-successfully {
  width: 120px;
  background: #d9fff7;
  border-radius: 50%;
  height: 120px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
